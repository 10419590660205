import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_nyaskosnoren/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {derived_properties: {color: { in: "svarta" }}}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				color
				elastic
				form
				waxed
				the_length
			}
			
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "svarta-skosnören"
    }}>{`Svarta Skosnören`}</h1>
    <p>{`Svarta skosnören är ofta ganska stilrena och passar till såväl finskor som till sneakers. Matchar du med ett par skor i en stark färg kan ett par svarta skosnören passa bra då de ger en bra kontrast. Ett par svarta skosnören är något alla bör ha liggandes i garderoben, du vet aldrig när du behöver ett extra par och ett par svarta passar till nästan vad som helst.`}</p>
    <CategoriesLinks mdxType="CategoriesLinks"></CategoriesLinks>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      